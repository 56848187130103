import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  deCH: {
    translation: {
      'Welcome to React': 'Wilkommen nach React',
      roadmap: 'berufswahl-fahrplan',
      documents: 'Dokumente',
      'download dossier': 'Dossier herunterladen',
      'apprenticeships and documents': 'Bewerbungen & Dokumente',
      // if you are updating classes, update path in ErrorBoundary as well
      classes: 'klassenübersicht',
      'student oversight': 'Schüleransicht',
      presentations: 'vorlagen',
      conventions: 'anmeldung-bewerbungsfoto',
      finder: 'berufs-finder',
      events: 'berufswahl-anlaesse',
      student: 'student',
      actions: 'Aktionen',
      further: 'Weiter',
      class: 'klasse',
      login: 'Anmelden',
      remove: 'Entfernen',
      cancel: 'Abbrechen',
      menu: 'Menü',
      my_profile: 'Mein Profil',
      settings: 'Einstellungen',
      logout: 'Abmelden',
      invite_to_jobfinder: 'Zum Berufs-Finder einladen',
      back: 'Zurück',
      back_to_class: 'Zurück zur Klasse',
      back_to_classoverview: 'Zurück zur Klassenübersicht',
      check_recruitment_profile: 'Bewerbungsprofil ansehen',
      send_a_reminder: 'Nachricht senden',
      filter_by: 'Filtern nach',
      login_header_title: 'Der \n Berufswahl-Radar',
      login_header_text:
        'Mit diesem Tool begleiten und unterstützen Sie die Jugendlichen dort, wo sie sich über die Berufswahl informieren und sich für Lehrstellen bewerben: online!',
      mail_placeholder: 'email@yousty.ch',
      first_name_placeholder: 'Max',
      last_name_placeholder: 'Muster',
      active_classes: 'Aktive Klassen',
      add_class: 'Klasse hinzufügen',
      entry_header: 'Willkommen im \nBerufswahl-Radar',
      entry_teaser1_header: 'Berufswahl-Fahrplan',
      entry_teaser2_header: 'Virtuelles Klassenzimmer',
      entry_teaser1_content:
        'Eine Übersicht über die Phasen der Berufswahl mit Vorlagen, Videos, Arbeitsblättern und vielem mehr.',
      entry_teaser1_button: 'Zum Fahrplan',
      entry_teaser2_content:
        'Hier können Sie Ihre Schulklasse optimal begleiten und ihnen bei der Berufswahl zur Seite stehen',
      entry_teaser2_button: 'Anmelden',
      entry_teaser2_button_alt: 'Zum Klassenzimmer',
      entry_sideTeaser1_header: 'Erfolgreich zur Lehrstelle',
      entry_sideTeaser1_content:
        'Unsere interaktive Broschüre unterstützt Sie und Ihre Schüler:innen optimal im Berufswahlprozess.',
      entry_sideTeaser1_button: 'Kostenlos bestellen',
      entry_sideTeaser2_header: 'SwissSkills 2025 Technology Partner',
      entry_sideTeaser2_content: 'Yousty ist Technology Partner der SwissSkills 2025.',
      entry_sideTeaser2_button: 'Mehr erfahren',
      entry_sideTeaser3_header: 'Kostenlose Bewerbungsfotos für Ihre Schulklasse',
      entry_sideTeaser3_content: 'Melden Sie Ihre Schulklasse an der Berufsmesse in Ihrer Nähe an.',
      entry_sideTeaser3_button: 'Anmelden',
      entry_topLink: 'Wie nutze ich den Berufswahl-Radar?',
      err_not_a_district: 'Kanton aus der Liste auswählen.',
      err_not_a_schooltype: 'Schulstufe aus der Liste auswählen.',
      back_to_Yousty: 'zurück zu yousty.ch',
      'Provided email doesnt fulfill requirements': 'Ungültige E-Mail-Adresse',
      error_password_too_short: 'Das Passwort muss mindestens 8 Zeichen enthalten',
      error_field_required: 'THIS FIELD IS REQUIRED',
      user_invalid_scope: 'Sie müssen als Lehrer registriert sein',
      forgot_password: 'Passwort vergessen?',
      password_remind: 'Anmelden',
      not_yet_registered: 'Noch kein Yousty-Konto?',
      register_account: 'Konto erstellen',
      roadmap_header: 'Der Berufswahl-Fahrplan',
      roadmap_subtitle: 'Behalten Sie den Überblick über die wichtigsten Phasen der Berufswahl',
      roadmap_ad_teaser_top_header:
        'Arbeitsdossier für Ihre Schüler:innen für den Berufswahlunterricht',
      roadmap_ad_teaser_top_button: 'Jetzt herunterladen und ausdrucken',
      roadmap_ad_teaser_1_header: 'Kennen Sie schon unsere interaktive Broschüre?',
      roadmap_ad_teaser_1_text:
        'Unsere Broschüre "Erfolgreich zur Lehrstelle" wurde in Zusammenarbeit mit Lehrpersonen entwickelt, um Ihnen kompakt die Informationen bereitzustellen, welche Sie brauchen, um Ihre Schüler:innen optimal bei der Lehrstellensuche zu begleiten. ',
      roadmap_ad_teaser_1_button: 'Kostenlos downloaden',
      roadmap_ad_teaser_2_header:
        'Behalten Sie die Bewerbungsaktivitäten Ihrer Klasse im Überblick',
      roadmap_ad_teaser_2_text:
        'Durch die Klassenübersicht können Sie die Bewerbungsunterlagen Ihrer Schüler einsehen und haben den Überblick über den Bewerbungsstatus.',
      roadmap_ad_teaser_2_button: 'Anmelden',
      document_teaser_heading: 'Vorlagen & Videos',
      document_teaser_link:
        'Zu jedem Thema bieten wir Ihnen ergänzende Zusatzinformationen (Videos, Checklisten und Musterdokumente), um Sie und insbesondere die Schüler in der Berufswahl zu unterstützen.',
      document_teaser_button_text: 'Zu den Vorlagen',
      document_teaser_pdf_header: 'Meist genutzte Dokumente',
      document_teaser_pdf_item1: 'Arbeitsblatt Interessen und Stärken',
      document_teaser_pdf_item2: 'Musterdokument Bewerbungsschreiben',
      document_teaser_pdf_item3: 'Mustervorlage Lebenslauf',
      document_teaser_media_header: 'Die hilfreichsten Videos',
      document_teaser_media_item1: 'How-To Bewerbungsschreiben',
      document_teaser_media_item2: 'How-To Bewerben',
      document_teaser_media_item3: 'Wie Scanne ich einfach und schnell Dokumente?',
      tab_1: 'Schüler:innen',
      tab_2: 'Bewerbungen dieser Klasse',
      tab_3: 'Fotos dieser Klasse',
      invite_card_heading: 'Schüler:innen',
      invite_card_button: 'Einladen',
      dynamic_teaser_heading: 'Wichtigste Vorlagen für Ihre Klasse',
      dynamic_teaser_link_text: 'Nützliche Vorlagen',
      dynamic_teaser_text:
        'Alle Vorlagen und Videos, um Ihre Klasse optimal im Berufswahl-Unterricht zu betreuen.',
      manage_class_caption_1: 'bestätigte Schüler:innen',
      manage_class_caption_2: 'unbestätigte Schüler:innen',
      manage_class_list_users: 'Zugriffsberechtigte',
      invite_to_job_check: 'Zum Berufs-Check einladen',
      action_card_link_1: 'Zum Berufs-Check einladen',
      action_card_link_2: 'Zum Berufs-Finder einladen',
      card_display_stat_text_1: 'lehrstellen-bewerbungen',
      card_display_stat_text_2: 'schnupper-bewerbungen',
      card_display_invite_student: 'Schüler einladen',
      pending_student_status: 'Nicht bestätigt',
      pending_student_button: 'Erinnerung senden',
      class_id: 'Klasse {{id}}',
      classes_header: 'Grüezi, \n',
      classes_teaser_header: 'So funktioniert die Klassenübersicht',
      classes_teaser_text:
        'Wählen Sie die jeweilige Klasse aus. In der virtuellen Klasse sehen Sie die Bewerbungsunterlagen Ihrer Schüler:innen und können sie zum Berufs-Finder und Berufs-Check einladen.',
      classes_teaser_button: 'Erklärvideo anschauen',
      edit_class_label_1: 'Name der Klasse',
      edit_class_label_2: 'Kanton',
      edit_class_label_3: 'Schulstufe',
      edit_class_label_4: 'Abschlussjahr der Klasse',
      edit_class_placeholder_1: 'Zürich',
      view_applications: 'Bewerbungen einsehen',
      tooltipjobFinder:
        'Der Berufs-Finder wurde durchgeführt, Klick auf das Icon öffnet das Resultat.',
      tooltipjobFinder_disabled:
        'Der Berufs-Finder wurde noch nicht durchgeführt. Klicken Sie auf den Namen des Jugendlichen, um eine Einladung zu verschicken.',
      tooltipjobCheck:
        'Der Berufs-Check wurde durchgeführt, Klick auf das Icon öffnet das Resultat.',
      tooltipjobCheck_disabled:
        'Der Berufs-Check wurde noch nicht durchgeführt. Klicken Sie auf den Namen des Jugendlichen, um eine Einladung zu verschicken.',
      tooltipapplied: 'Die erste Bewerbung wurde verschickt.',
      tooltipapplied_disabled: 'Es wurde noch keine Bewerbung versendet.',
      tooltipconfirmed: 'Die Zusage für die Lehrstelle wurde erteilt.',
      tooltipconfirmed_disabled: 'Es wurde noch keine Zusage für eine Lehrstelle erteilt.',
      tooltipstatus:
        'Je nach Vollständigkeit des Profils  ändert sich die Farbe. Je höher der Prozenzsatz, desto vollständiger ist das Profil.',
      tooltip_job_check_on_maintenance:
        'Aktuell steht unser Berufs-Check aufgrund eines technischen Problems nicht zur Verfügung. Wir bedauern die Unannehmlichkeiten zutiefst und bitten um dein Verständnis.',
      edit_class_placeholder_2: 'Sekundarschule Sek A',
      delete_class_confirmation:
        'Sind Sie sicher, dass Sie diese Klasse und damit auch alle Schüler dieser Klasse löschen wollen?',
      delete_class_button_1: 'Klasse löschen',
      delete_class_button_2: 'Abbrechen',
      info_unconfirmed_students_photos_will_not_be_shown:
        'Fotos von Schülern, welche in der Schülerübersicht noch nicht bestätigt sind, sind im Download nicht enthalten',
      invite_student_email: ' wurde erfolgreich eingeladen!',
      invite_student_reminder_email: '{{name}} wurde erfolgreich erinnert!',
      invite_teacher_label_1: 'E-Mail',
      invite_teacher_label_2: 'Vorname',
      invite_teacher_label_3: 'Nachname',
      invite_teacher_label_4: 'E-Mail-Adresse',
      invite_teacher_placeholder_1: 'Muster',
      invite_teacher_placeholder_2: 'Mustermann',
      invite_teacher_par_1:
        'Bitte stellen Sie sicher, dass die Lehrperson welche Sie einladen möchten, einen Account auf Yousty besitzt.',
      invite_teacher_par_2: 'Ich bin berechtigt diese Lehrperson hinzuzufügen',
      invite_teacher_button_1: 'Einladen',
      action_card_title: 'Aktionen',
      action_card_subtitle:
        'Auf Basis des abgefragten Schulstoffes, empfehlen wir eine Einladung zum Berufs-Check für Schüler:innen ab der 3. Oberstufe',
      deadlines_heading: 'Verlauf',
      deadlines_placeholder: 'Es sind keine Aktivitäten für die Klasse vorhanden.',
      student_card_application_count: 'Lehrstellen-Bewerbungen',
      student_card_trial_application_count: 'Schnupper-Bewerbungen',
      student_card_job_finder_pdf_url: 'Berufs-Check Resultat',
      edit_class_modal_button: 'Speichern',
      studentName: 'Schüler/in',
      jobName: 'Beruf',
      companyName: 'Firma',
      applicationType: 'Typ',
      status: 'Status',
      sentAt: 'Gesendet am',
      applications: 'Bewerbungen',
      drafts: 'Entwürfe',
      user_filled_profile: '{{firstName}} hat sein Profil zu {{profileCompletion}}% ausgefüllt',
      delete_student: 'Schüler löschen',
      confirm_deleting_student_from_class:
        'Sind Sie sicher, dass Sie {{firstName}} {{lastName}} aus Ihrer Klasse loschen wollen?',
      confirm_deleting_student_from_class_impers:
        'Sind Sie sicher, dass Sie diesen Schüler aus Ihrer Klasse löschen wollen?',
      filled: 'ausgefüllt',
      filtering: 'Filtern nach',
      tab_1_route: '',
      tab_2_route: 'apps',
      tab_3_route: 'fotos',
      photos_heading_1: 'Fotos von Berufsmessen',
      photos_heading_2: 'Fotos von Ihrer Klasse',
      photos_all_link: 'Alle Fotos herunterladen',
      invite_student_to_jobfinder: '{{name}} zum Berufs-Finder einladen',
      jobfinder_invitation_text:
        'Aus über 250 Lehrberufen den richtigen zu finden ist nicht einfach. Deshalb hat Yousty ein Tool entwickelt, um den Schülern diesen Prozess zu vereinfachen. Durch das Beantworten von 33 Fragen in 3 Minuten werden den Lehrstellensuchenden eine Top drei, sowie siebzehn weitere Berufe angezeigt, die zu ihren Interessen passen.',
      jobfinder_invitation_button_caption: '{{name}} einladen',
      berufsfinder_results_header: 'Berufs-Finder Resultate von {{firstName}} {{lastName}}',
      download_top_x_results: 'Top {{x}} anzeigen',
      unassigned: 'Nicht zugeordnet',
      no_photos: 'Für diese Klasse sind keine Fotos vorhanden.',
      unassign: 'Verknüpfung aufheben',
      choose_option: 'Bitte wählen',
      add_teacher_success: 'Lehrer wurde erfolgreich hinzugefügt.',
      add_teacher_failure: 'Lehrer wurde nicht hinzugefügt.',
      add_teacher_already_added: 'Lehrer wurde bereits zugewiesen.',
      no_apprenticeships_yet: 'Diese/r Schüler*in hat noch keine Bewerbung verschickt.',
      no_drafts_yet: 'Diese/r Schüler*in hat noch keine Entwürfe.',
      error_404: 'Diese Seite existiert nicht. Sie werden zum Login weitergeleitet.',
      sidebar_roadmap_link: 'Home',
      sidebar_classes_link: 'Klassenübersicht',
      sidebar_conventions_link: 'Berufsmessen',
      sidebar_presentations_link: 'Videos & Vorlagen',
      sidebar_finder_link: 'Berufs-Finder',
      sidebar_choice_link: 'Informationsveranstaltungen',
      invitation_inviting: '{{name}} einladen...',
      invitation_success: '{{name}} wurde eingeladen!',
      invitation_error: 'Fehler. Erneut versuchen?',
      internal_error: 'Internal Server Error',
      ads_presentations_header: 'Vorlagen und Informationen rund um die Berufsorientierung',
      ads_presentations_subheader: 'Unterstützen Sie die Schüler ideal bei der Berufswahl',
      ads_presentations_text:
        'Zu jedem Thema bieten wir Ihnen ergänzende Zusatzinformationen (Videos, Checklisten und Musterdokumente), um Sie und insbesondere die Schüler:innen in der Berufswahl zu unterstützen.',
      ads_presentations_button_text: 'Zu den Vorlagen',
      ads_secondaryteaser_header: 'Kennen Sie schon unsere Broschüre?',
      ads_secondaryteaser_text:
        'Unsere Broschüre "Erfolgreich zur Lehrstelle" wurde in Zusammenarbeit mit Lehrpersonen entwickelt, um Ihnen kompakt die Informationen bereitszustellen, welche Sie brauchen, um Ihre Schüler:innen optimal bei der Berufswahl und Lehrstellensuche zu begleiten. ',
      ads_secondaryteaser_button_text: 'Broschüre bestellen',
      ads_finder_header: 'In 3 Minuten zum passenden Beruf',
      ads_finder_subheader: 'Mit dem Berufs-Finder herausfinden, welcher Beruf zum einem passt',
      ads_finder_text:
        'Welcher Beruf passt eigentlich zu mir? Diese Frage stellen sich bestimmt auch Ihre Schüler:innen. Während drei Minuten beantworten die Jugendlichen mittels Emojis 33 Fragen zu ihren Interessen und Fähigkeiten. Im Anschluss erhalten die Schüler:innen eine Übersicht zu den 20 passendsten Lehrberufen.',
      ads_finder_button_text: 'Zum Berufs-Finder',
      ads_events_header: 'Informationsveranstaltungen',
      ads_events_subheader:
        'Hier gibt es eine Übersicht über alle Veranstaltungen rund um die Berufswahl',
      ads_events_text:
        'Neben den Schnupperlehren sind auch Informationsveranstaltungen ein guter Weg, um mehr über einen Beruf und/oder eine Firma zu erfahren. Auf Yousty finden die Lehrstellensuchenden eine Übersicht über alle Veranstaltungen und können sich direkt dafür anmelden.',
      ads_events_button_text: 'Zur Übersicht',
      ads_conventions_header: 'Professionelle Bewerbungsfotos für die ganze Klasse',
      ads_conventions_subheader:
        'Melden Sie Ihre Klasse zum Fotoshooting für ein professionelles Bewerbungsfoto an',
      ads_conventions_text:
        'Auch im Messejahr {{year}} bieten wir an vielen Berufsmessen der Schweiz professionelle und kostenlose Bewerbungsfotos an. Melden Sie jetzt Ihre Klasse an.',
      ads_conventions_button_text: 'Klasse anmelden',
      gender_female: 'Weiblich',
      gender_male: 'Männlich',
      accept_terms_1: 'Nutzungsbedingungen',
      accept_terms_2: 'akzeptieren',
      timeout_error_header: 'Sitzung abgelaufen',
      timeout_error_text: 'Bitte erneut einloggen',
      no_access_to_class: 'Sie haben keinen Zugang zu dieser Klasse.',
      err_fetching: 'Es ist ein Fehler aufgetreten.',
      fill_later: 'Klasse später hinzufügen',
      err_select_an_endyear: 'Bitte ein Abschlussjahr auswählen',
      accept_terms: 'Nutzungsbedingungen akzeptieren',
      email_confirm_explain: 'Bitte bestätigen Sie Ihre E-Mail Adresse.',
      email_confirm_question: 'Keine E-Mail erhalten?',
      email_confirm_link: 'Bestätigungsmail erneut senden',
      not_found_text: 'Seite nicht gefunden',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    fallbackLng: 'deCH',

    keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
