import { roadmapActions } from '../rootTypes';

export const INITIAL_STATE = {
  phases: {
    SwissSkills: {
      name: 'SwissSkills',
      active: true,
      text: 'SwissSkills',
    },
    picking: {
      name: 'picking',
      active: false,
      text: 'Berufswahl',
    },
    preview: {
      name: 'preview',
      active: false,
      text: 'Schnupperlehren',
    },
    recruitment: {
      name: 'recruitment',
      active: false,
      text: 'Bewerbungsphase',
    },
    interview: {
      name: 'interview',
      active: false,
      text: 'Vorstellungsgespräch',
    },
    jobfair: {
      name: 'jobfair',
      active: false,
      text: 'Berufsmessen',
    },
    // SwissSkills: {
    //   name: 'SwissSkills',
    //   active: false,
    //   text: 'SwissSkills',
    // },
  },
  fetching: true,
  teasers: [{}, {}, {}, {}, {}, {}],
};

const roadmapState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case roadmapActions.setActiveField:
      return {
        ...state,
        phases: Object.keys(state.phases).reduce((acc, phase) => {
          acc[phase] = {
            ...state.phases[phase],
            active: state.phases[phase].name === action.payload,
          };
          return acc;
        }, {}),
      };
    case roadmapActions.fetchContentRequest:
      return { ...state };
    case roadmapActions.fetchContentPending:
      return { ...state, fetching: true };
    case roadmapActions.fetchContentSuccess:
      return {
        ...state,
        fetching: false,
        teasers: action.payload,
      };
    default:
      return state;
  }
};

export default roadmapState;
